import React from "react";
import FeedWidget from "./components/feed/FeedWidget";
import Form from "./components/form/Form";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PublicForm from "./pages/PublicForm/PublicForm";
import IframeForm from "./pages/IframeForm/IframeForm";
import IFrameFeed from "./pages/iFrameFeed/IFrameFeed";
import TravelFeed from "./pages/TestFeeds/TravelFeed";
import TestFeed from "./pages/TestFeeds/TestFeed";

function App(props) {
  if (props.feedId) {
    return (
      <FeedWidget feedId={props.feedId} feedVersion={props?.feedVersion} />
    );
  } else if (props.campaignId) {
    return <Form campaignId={props.campaignId} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/form/v2/:campaignId" element={<PublicForm />} />
        <Route path="/iframe/v3/:campaignId" element={<IframeForm />} />
        <Route path="/iframe/feed/:feedId" element={<IFrameFeed />} />
        <Route path="/travelfeed/:feedId" element={<TravelFeed />} />
        <Route path="/testfeed/:feedId" element={<TestFeed />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
