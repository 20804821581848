import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;

  font-family: Sans-serif;
`;

export const WidgetContainer = styled.div`
  padding: 3rem 1rem;

  background-color: #f5f5f5;
  margin: 1rem auto;

  @media (min-width: 768px) {
    padding: 3rem;
    width: 50%;
  }
`;

export const Header = styled.h1`
  font-family: Sans-serif;
  color: #606060;
`;

export const Paragraph = styled.p`
  font: normal 14px / 1.25em helvetica-w01-light, sans-serif;
  color: #606060;
`;
export const RenderContainer = styled.div`
  margin-top: 3rem;
`;
