import axios from "axios";

export const fetchFeedData = async (feedId, cacheAge) => {
  let url = `${process.env.REACT_APP_FIREBASE_API_URL}/feed/${feedId}${
    cacheAge ? `?cache_age=${cacheAge}` : ""
  }`;

  const {data} = await axios({
    url: url,
    method: "get",
  });
  return data;
};
