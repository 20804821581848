import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {fetchFeedData} from "../../services/FeedService";
import LoadingScreen from "../../components/commons/LoadingScreen";
import RenderFeed from "../../components/feed/render-feed/RenderFeed";
import * as S from "./styles";

export default function TravelFeed() {
  const {feedId} = useParams();
  const [feed, setFeed] = useState();
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchFeedData(feedId, 60 * 60 * 24).then((response) => {
      setFeed(response.feed);
      setAssets(response.assets);

      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingScreen />;

  return feed && assets ? (
    <S.Container>
      <S.WidgetContainer>
        <S.Header>Travel Feed</S.Header>
        <S.Paragraph>
          Do you want to be featured here? Post your video or photo on
          Instagram, using the hashtag #mycustomermomen
        </S.Paragraph>

        <RenderFeed feed={feed} assets={assets} />
      </S.WidgetContainer>
    </S.Container>
  ) : null;
}
